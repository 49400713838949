/*
 * @Author: hongying
 * @Date: 2022-03-16 09:59:46
 * @LastEditTime: 2022-05-16 14:23:44
 * @LastEditors: ying.hong ying.hong@ubtrobot.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ADIS\src\utils\constant.ts
 */


export const getHardwareJson = () => {
  const sensorList = [
    {key: 40010000, name: 'bottom lidar', desc: '底部雷达'},
    {key: 40020000, name: 'top lidar', desc: '顶部雷达'},
    {key: 41010000, name: 'ultrasonic1', desc: '1号超声'},
    {key: 41020000, name: 'ultrasonic2', desc: '2号超声'},
    {key: 41030000, name: 'ultrasonic3', desc: '3号超声'},
    {key: 41040000, name: 'ultrasonic4', desc: '4号超声'},
    {key: 41050000, name: 'ultrasonic5', desc: '5号超声'},
    {key: 42010000, name: '', desc: '1号红外 (SENSOR_IR1) 墙检红外1'},
    {key: 42020000, name: '', desc: '2号红外 (SENSOR_IR2) 墙检红外2'},
    {key: 42030000, name: '', desc: '3号红外 (SENSOR_IR3) 墙检红外3'},
    {key: 42040000, name: '', desc: '4号红外 (SENSOR_IR4) 墙检红外4'},
    {key: 42050000, name: '', desc: '5号红外 (SENSOR_IR5) 墙检红外5'},
    {key: 42060000, name: '', desc: '6号红外 (SENSOR_IR6) 墙检红外6'},
    {key: 42070000, name: 'floor_ir', desc: '7号红外 (SENSOR_IR7) 地检红外'},
    {key: 42080000, name: '', desc: '8号红外 (SENSOR_IR8) 回充红外1'},
    {key: 42090000, name: '', desc: '9号红外 (SENSOR_IR9) 回充红外2'},
    {key: 43010000, name: '', desc: '腰部RGBD深度'},
    {key: 43020000, name: '', desc: '腰部RGBD彩色'},
    {key: 43030000, name: 'rgbd_top_depth', desc: '顶部RGBD深度'},
    {key: 43040000, name: '', desc: '顶部RGBD彩色'},
    {key: 43050000, name: 'rgbd_top_imu', desc: '顶部RGBD IMU'},
    {key: 43060000, name: 'camera', desc: '摄像头'},
    {key: 45010000, name: 'geomagnetism', desc: '地磁'},
    {key: 46010000, name: 'imu', desc: 'IMU'},
    {key: 48010000, name: 'ntc_temperature', desc: ' NTC温度传感器'},
    {key: 50010000, name: 'rgbd calibrated status', desc: ' rgbd校准'},
  ]
  const deviceList = [
    {key: 20010000, name: 'motor1', desc: '1号电机（DEVICE_MOTOR1）'},
    {key: 20020000, name: 'motor2', desc: '2号电机（DEVICE_MOTOR2）'},
    {key: 21000000, name: 'uv', desc: '所有UV灯&镇流器'},
    {key: 22000000, name: 'fan', desc: '风扇DEVICE_FAN'},
    {key: 22010000, name: 'fan1', desc: '风扇DEVICE_FAN1'},
    {key: 22020000, name: 'fan2', desc: '风扇DEVICE_FAN2'},
    {key: 22030000, name: 'fan3', desc: '风扇DEVICE_FAN3'},
    {key: 23040000, name: 'bms', desc: 'BMS连接情况'},
  ]
  const systemList = [
    {key: 11010000, name: "img version", desc: 'ubuntu镜像版本 ' },
    {key: 11020000, name: "system firmware version", desc: '系统控制板版本'},
    {key: 11030000, name: "motor firmware version", desc: '电机控制板版本 '},
    {key: 11040000, name: "sensor firmware version", desc: '传感器板版本 '},
    {key: 11050000, name: "geomagnestism firmware version", desc: '地磁板软件版本   '},
    {key: 11060000, name: "bluetooth firmware version", desc: '蓝牙主机软件版本'},
    {key: 11070000, name: "d435i firmware version", desc: 'rgbd固件版本   '},
    {key: 11080000, name: "wj716 firmware version", desc: '万集716雷达固件版本'},
    {key: 11090000, name: "wj718 firmware version", desc: '万集718雷达固件版本'},
    {key: 11100000, name: "bms firmware version", desc: '电池管理模块固件版本'},
     
  ]
  return {
    deviceList,
    sensorList,
    systemList,
  }
}

export const hourList = () => {
  return [
    {key: '0', value: '00:00:00'},
    {key: 1, value: '01:00:00'},
    {key: 2, value: '02:00:00'},
    {key: 3, value: '03:00:00'},
    {key: 4, value: '04:00:00'},
    {key: 5, value: '05:00:00'},
    {key: 6, value: '06:00:00'},
    {key: 7, value: '07:00:00'},
    {key: 8, value: '08:00:00'},
    {key: 9, value: '09:00:00'},
    {key: 10, value: '10:00:00'},
    {key: 11, value: '11:00:00'},
    {key: 12, value: '12:00:00'},
    {key: 13, value: '13:00:00'},
    {key: 14, value: '14:00:00'},
    {key: 15, value: '15:00:00'},
    {key: 16, value: '16:00:00'},
    {key: 17, value: '17:00:00'},
    {key: 18, value: '18:00:00'},
    {key: 19, value: '19:00:00'},
    {key: 20, value: '20:00:00'},
    {key: 21, value: '21:00:00'},
    {key: 22, value: '22:00:00'},
    {key: 23, value: '23:00:00'},
  ]
} 