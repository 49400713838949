<template>
  <div class="robotinfo">
    <el-breadcrumb separator="/" class="breadcrumb">
      <!-- <el-breadcrumb-item :to="{ path: '/robot/robotList' }"> -->
      <el-breadcrumb-item @click="gotorobotlists" class="needGo">
        {{ $t("route.robot") }}
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{ $t("robot.detail") }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="robotDetailBox">
      <div class="robotDetail">
        <div class="topMenu" v-if="info.status === 'binded'">
          <!-- <el-button @click="gocretedDetail"> -->
          <!-- <el-button @click="turnoffs"> -->
          <el-button class="button" @click="stabilization(syncstatus)">
            {{ $t('disinfect.tbzt') }}
          </el-button>
          <!-- <el-button class="button" @click="resetLoginPwd">{{ $t("password.word") }}</el-button> -->
          <el-button class="button" @click="stabilization(createddock)" @keyup.space.prevent.native @keydown.enter.prevent.native>
            {{
                $t("task.yjhz")
            }}
          </el-button>
          <el-button class="button" @click="chongqifalgechaxun" @keyup.space.prevent.native @keydown.enter.prevent.native>
            {{ $t('disinfect.yjchongqi') }}
          </el-button>
          <el-button class="button" @click="turnfalgechaxun" @keyup.space.prevent.native @keydown.enter.prevent.native>
            {{ $t("turn.guanji") }}
          </el-button>
          <el-button type="primary" @click="stabilization(gocretedDetail)" @keyup.space.prevent.native @keydown.enter.prevent.native>
            {{
                $t("creted.renwu")
            }}
          </el-button>
        </div>
        <div class="topMenu" v-else>
          <el-button @click="delRobotFunc" class="button" @keyup.space.prevent.native @keydown.enter.prevent.native>
            {{ $t('system.delete') }}
          </el-button>
        </div>
        <div class="robot">
          <img src="@/assets/images/robot/robot.png" alt class="robotImg">
          <span class="wifiStatus"
            :style="{ background: (!info.online || info.status != 'binded') ? '#acacac' : '' }">{{
                info.status === "binded" && info.online
                  ? info.net_type
                  : info.status === "binded" && !info.online
                    ? $t("robot.offline")
                    : $t('disinfect.yijlk')
            }}</span>
          <!-- !info.door_sensor.pair?'#acacac': info.door_sensor?info.door_sensor.status==='disconnect':info.door_sensor.pair -->
          <!-- <span class="cardStatus" :style="{background:info.door_sensor?info.door_sensor.status==='connected'?'':'#acacac':'#acacac'}">{{$t("creted.mjp")}} {{ info.door_sensor
                      ? getDoorStatus(
                          info.door_sensor.status,
                          info.door_sensor.pair,
                          1
                        )
                      : $t("robot.unpaired")}}</span> -->
        </div>
        <div class="infoContent">
          <div class="info-item">
            <p>
              <span class="info-robotName">{{ info.nickname }}</span>
              <i class="el-icon-edit editNameButton" @click="openChangeName" @keyup.space.prevent.native @keydown.enter.prevent.native />
              <span class="getlog" v-show="info.status != 'leave' && info.online" @click="getLogBox">{{
                  info.online ? $t("robot.getLog") : ""
              }}</span>
            </p>
            <div class="row">
              <div class="battery">
                <div class="batteryValue"
                  :style="{ width: info.battery_info ? info.battery_info.level + '%' : info.battery + '%', background: info.battery_info ? info.battery_info.level < 10 ? 'red' : '#5BD171' : info.battery < 10 ? 'red' : '#5BD171' }" />
              </div>
              <div class="baytteryPercent">
                {{ info.battery_info ? info.battery_info.level + '%' : info.battery ? info.battery + "%" : '' }}
              </div>
            </div>
            <div class="chargeds">
              {{ info.battery_info ? setcharges(info.battery_info.charge_source, info.battery_info.level) : '' }}
            </div>
            <p>
              <span>{{ $t("creted.mjp") }}：</span>
              <span class="info-item-content">
                {{ info.door_sensor
                    ? getDoorStatus(
                      info.door_sensor.status,
                      info.door_sensor.pair,
                      1
                    )
                    : $t("robot.unpaired")
                }}
              </span>
            </p>
          </div>
          <div class="info-item">
            <p>
              <span>{{ $t("robot.lastOnlineTime") }}：</span>
              <span class="info-item-content">{{
                  info.last_online
                    ? moment(info.last_online * 1000).format(momentStamp('datetime'))
                    : null
              }}</span>
            </p>
            <p>
              <span>{{ $t("robotInfo.zjlxsj") }}：</span>
              <span class="info-item-content">{{
                  info.last_offline
                    ? moment(info.last_offline * 1000).format(momentStamp('datetime'))
                    : null
              }}</span>
            </p>
            <p class="row">
              <span>{{ $t("robotInfo.hardwareStatus") }}：</span>
              <span class="info-item-content">
                <span>{{ dniParams.isDevice && dniParams.isSensor && dniParams.isSystem ? $t("robotInfo.noAbnormality")
                    : ''
                }}</span>
                <span>
                  {{ dniParams.isDevice === false ? $t("robotInfo.deviceError") : '' }}
                  {{ dniParams.isSensor === false ? $t("robotInfo.sensorError") : '' }}
                  {{ dniParams.isSystem === false ? $t("robotInfo.systemError") : '' }}
                </span>
              </span>
              <el-popover trigger="click" :width="500">
                <div slot="content" class="hardwardBox">
                  <div class="normalTitle" v-if="dniParams.errorNum > 0">
                    <i class="el-icon-warning" style="color: red;" />{{ $t("robotInfo.fault") }} ({{ dniParams.errorNum
                    }})
                  </div>
                  <div v-for="(item, i) in dniParams.systemlist" v-show="item.error_value !== 0" class="hardItem">
                    <span class="itemTitle">{{ item.device_name }}</span><span class="itemDes" :title="item.message">{{
                        item.message
                    }}</span>
                  </div>
                  <div v-for="(item, i) in dniParams.devicelist" v-show="item.error_value !== 0" class="hardItem">
                    <span class="itemTitle">{{ item.device_name }}</span><span class="itemDes" :title="item.message">{{
                        item.message
                    }}</span>
                  </div>
                  <div v-for="(item, i) in dniParams.sensorlist" v-show="item.error_value !== 0" class="hardItem">
                    <span class="itemTitle">{{ item.device_name }}</span><span class="itemDes" :title="item.message">{{
                        item.message
                    }}</span>
                  </div>

                  <div class="normalTitle" v-if="dniParams.normalNum > 0">
                    <i class="el-icon-success" style="color: #5bd171;" />{{ $t("robotInfo.nomal") }} ({{
                        dniParams.normalNum
                    }})
                  </div>
                  <div class="hardTitle">
                    {{ $t("robotInfo.system") }}
                  </div>
                  <div v-for="(item, i) in dniParams.systemlist" v-show="item.error_value === 0" class="hardItem">
                    <span class="itemTitle">{{ item.device_name }}</span>
                    <span class="itemDes" :title="item.message">
                      <i class="el-icon-success" style="color: #5bd171;" />
                    </span>
                  </div>
                  <div class="hardTitle">
                    {{ $t("robotInfo.device") }}
                  </div>
                  <div v-for="(item, i) in dniParams.devicelist" v-show="item.error_value === 0" class="hardItem">
                    <span class="itemTitle">{{ item.device_name }}</span>
                    <span class="itemDes" :title="item.message">
                      <i class="el-icon-success" style="color: #5bd171;" />
                    </span>
                  </div>
                  <div class="hardTitle">
                    {{ $t("robotInfo.sensor") }}
                  </div>
                  <div v-for="(item, i) in dniParams.sensorlist" v-show="item.error_value === 0" class="hardItem">
                    <span class="itemTitle">{{ item.device_name }}</span>
                    <span class="itemDes" :title="item.message">
                      <i class="el-icon-success" style="color: #5bd171;" />
                    </span>
                  </div>
                </div>
                <template #reference>
                  <img src="@/assets/images/robot/detail.png" alt class="infoImg"
                    v-if="dniParams.isDevice && dniParams.isSensor && dniParams.isSystem">
                  <i class="el-icon-warning infoImg" style="color: red;"
                    v-else-if="dniParams.isDevice === false || dniParams.isSensor === false || dniParams.isSystem === false" />
                  <span v-else />
                  <!--无数据-->
                </template>
              </el-popover>
            </p>
          </div>
          <div class="info-item">
            <p class="row">
              <span class="infoLeft">{{ $t("robot.factorySerial") }}：</span>
              <span class="info-item-content">{{ info.sn }}</span>
              <el-popover trigger="click" :width="curLanguage=='es'?442:342">
                <div slot="content" class="versionTip" :class="curLanguage">
                  <div class="row">
                    <div class="infoLeft">
                      {{ $t("robot.factorySerial") }}：
                    </div>
                    <div class="infoRight" :title="info.sn">
                      {{ info.sn }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="infoLeft">
                      {{ $t("robot.softwareSerial") }}：
                    </div>
                    <div class="infoRight" :title="info.software_num">
                      {{ info.software_num }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="infoLeft">
                      {{ $t("robot.doorControl2") }}：
                    </div>
                    <div class="infoRight" :title="info.door_sensor && info.door_sensor.mac || ''">
                      {{ info.door_sensor && info.door_sensor.mac || $t("robot.doorControlnone") }}
                    </div>
                    <div />
                  </div>
                  <div class="row">
                    <div class="infoLeft">
                      {{ $t("robot.ddisName") }}：
                    </div>
                    <div class="infoRight" :title="info.hint_nickname">
                      {{ info.hint_nickname }}
                    </div>
                    <div />
                  </div>
                </div>
                <template #reference>
                  <img src="@/assets/images/robot/more.png" alt class="infoImg">
                </template>
              </el-popover>
            </p>
            <p class="row">
              <span class="infoLeft">{{ $t("robot.edition") }}：</span>
              <span class="info-item-content">{{ info.version ? info.version.total : null }}&emsp;</span>
              <img @click="getVersion(info.version)" src="@/assets/images/robot/detail.png" alt class="infoImg">
            </p>
          </div>
          <!-- <el-col :sm="8" :lg="8" class="infoItem">
            <p>
              <span>{{ setRobotName(info.software_num) }}</span>
              <span style="color: #1890ff; cursor: pointer" @click="getLogBox">{{
              info.online ? $t("robot.getLog") : ""
            }}</span>
            </p>
            <p>
              <span>{{ $t("robot.onlineStatus") }}</span>
              <span :style="`color: ${
                info.status === 'binded' && info.online ? '#4caf50' : '#999'
              }`">{{
                info.status === "binded" && info.online
                  ? $t("robot.online")
                  : info.status === "binded" && !info.online
                  ? $t("robot.offline")
                  : $t('disinfect.yijlk')
              }}</span>
            </p>
            <p>
              <span>{{ $t("robot.factorySerial") }}</span>
              <span>{{ info.sn }}</span>
            </p>
            <p>
              <span>{{ $t("robot.softwareSerial") }}</span>
              <span>{{ info.software_num }}</span>
            </p>
          </el-col>
          <el-col :sm="8" :lg="8" class="infoItem">
            <p>
              <span>{{ $t("robot.cumulativeDisinfectTask") }}</span>
              <span>{{
              info.stat_count ? info.stat_count + $t("robot.second") : null
            }}</span>
              <span class="checkDetail" @click="goDisinfect">{{
              $t("robot.viewDetails")
            }}</span>
            </p>
            <p>
              <span>{{ $t("robot.cumulativeDisinfectTime") }}</span>
              <span>{{ getHM(info.stat_time) }}</span>
            </p>
            <p>
              <span>{{ $t("robot.firstActivationTime") }}</span>
              <span>{{
              info.CreatedAt
                ? moment(info.CreatedAt).format("YYYY-MM-DD HH:mm:ss")
                : null
            }}</span>
            </p>
            <p>
              <span>{{ $t("robot.lastOnlineTime") }}</span>
              <span>{{
              info.UpdatedAt
                ? moment(info.UpdatedAt).format("YYYY-MM-DD HH:mm:ss")
                : null
            }}</span>
            </p>
          </el-col> -->
        </div>
      </div>
      <div>
        <div class="taskCount">
          <el-button class="button" @click="goDisinfect" @keyup.space.prevent.native @keydown.enter.prevent.native>
            {{ $t("robotInfo.ckxsrw") }}
          </el-button>
          <div class="title">
            {{ $t("robotInfo.xsrwtj") }}
          </div>
          <div class="info">
            <div class="info-item">
              <img src="@/assets/images/robot/task.png" alt>
              <div class="right">
                <div class="top">
                  {{ info.stat_count ? info.stat_count + $t("robot.second") : null }}
                </div>
                <div class="bottom">
                  {{ $t("robotInfo.ljrwcs") }}
                </div>
              </div>
            </div>
            <div class="info-item">
              <img src="@/assets/images/robot/time.png" alt>
              <div class="right">
                <div class="top">
                  {{ getHM(info.stat_time) }}
                </div>
                <div class="bottom">
                  {{ $t("robotInfo.ljxssc") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="taskCount">
            <div>
              <img :src="totalImg.eq" alt />
              <p>{{ $t("robot.remainingPower") }}</p>
              <p>{{ info.battery ? info.battery + "%" : null }}</p>
            </div>
            <div>
              <img :src="totalImg.ac" alt />
              <p>
                <span>{{ $t("robot.doorControl") }}&emsp;</span>
                <span :style="`color: ${
                    info.door_sensor
                      ? getDoorStatus(
                          info.door_sensor.status,
                          info.door_sensor.pair,
                          0
                        )
                      : null
                  }`">{{
                    info.door_sensor
                      ? getDoorStatus(
                          info.door_sensor.status,
                          info.door_sensor.pair,
                          1
                        )
                      : null
                  }}</span>
                <span />
              </p>
              <p>
                <span>{{
                  info.door_sensor ? info.door_sensor.mac : null
                }}</span>
              </p>
            </div>
            <div>
              <img :src="totalImg.network" alt />
              <p>{{ $t("robot.network") }}</p>
              <p>{{ info.net_type }}</p>
            </div>
            <div>
              <img :src="totalImg.edition" alt />
              <p>{{ $t("robot.edition") }}</p>
              <p>
                <span>{{ info.version ? info.version.total : null }}&emsp;</span>
                <span>
                  <img :src="info.version ? totalImg.appstoreAdd : null" class="appstore"
                    @click="getVersion(info.version)" />
                </span>
              </p>
            </div>
        </div> -->
        <div class="taskList">
          <div class="title">
            {{ $t("robot.timedTask") }}
          </div>
          <el-table :data="tableDatatiming" style="width: 100%" @row-click="gotoediting" :empty-text="$t('system.noData')">
            <!-- :class="{'cursorpointers':tableDatatiming.length>0}" -->
            <el-table-column prop="room_name" :label="$t('disinfect.room')" />
            <el-table-column prop="type" :label="$t('disinfect.pattern')">
              <template #default="{ row }">
                {{ disinfectType(row.type) }}
              </template>
            </el-table-column>
            <el-table-column prop="runupTime" :label="$t('disinfect.qdsj')" />
            <!-- <el-table-column prop="operation" label="操作"  @click="removetimingtask" /> -->
            <el-table-column :label="$t('system.operation')" width="100">
              <template #default="scope">
                <el-button type="text" size="small" @click.stop="
                  removetimingtask(scope.$index, tableDatatiming)
                " @keyup.space.prevent.native @keydown.enter.prevent.native>
                  {{ $t("disinfect.shanchu") }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="addtimingtask cursorpointers" v-show="tableDatatiming.length < 20"
            @click="stabilization(addtimingtasks)">
            <img src="@/assets/images/robot/add.png" alt class="addImg"> {{ $t("disinfect.tjdsrw") }}
          </div>
        </div>
        <!-- <el-tabs v-model="activeName" class="partContent">
        <el-tab-pane :label="$t('robot.systemStatus')" name="1">

        </el-tab-pane>
        <el-tab-pane :label="$t('robot.timedTask')" name="2">

        </el-tab-pane>
      </el-tabs> -->
      </div>
      <el-dialog v-model="dialogVisible" :title="$t('robot.getLog')" width="750px" :close-on-click-modal="false"
        @close="cancelLogDialog">
        <div class="logBox" v-loading="loading" :element-loading-text="`${$t('robot.getting')}...`">
          <div>
            <span :class="{ logActive: type == 'ros' }" class="logitem" @click="changeLog('ros')">{{ $t("robot.rosLog")
            }}</span>
            <span :class="{ logActive: type == 'nav' }" class="logitem" @click="changeLog('nav')">{{ $t("robot.navLog")
            }}</span>
          </div>
          <div class="dateBox">
            <!-- <span>{{ $t("robot.lately") }}</span> -->
            <!-- <el-select v-model="time" :placeholder="$t('robot.choice')" @change="getLogTime" size="medium"
              style="width: 150px; margin: 0 10px">
              <el-option v-for="item in timeList" :key="item.key" :label="item.value" :value="item.key" />
            </el-select> -->
            <div class="dataOneItem">
              <span>{{ $t('robotInfo.log.startWord') }}</span>
              <el-date-picker class="startTime" v-model="logStartTime" type="date" :format="momentStamp('date')"
                :disabled-date="disabledDate" :placeholder="$t('robotInfo.log.chooseTime')" />
              <el-select v-model="logStartHour" class="startHour" clearable>
                <el-option v-for="item in hourList" :key="item.key" :label="item.value" :value="item.key" />
              </el-select>
            </div>
            <div class="dataOneItem">
              <span>{{ $t('robotInfo.log.endWord') }}</span>
              <el-date-picker class="endTime" v-model="logEndTime" type="date" :format="momentStamp('date')"
                :disabled-date="disabledDate2" :placeholder="$t('robotInfo.log.chooseTime')" />
              <el-select v-model="logEndHour" class="endHour" clearable>
                <el-option v-for="item in hourList" :key="item.key" :label="item.value" :value="item.key" />
              </el-select>
            </div>
            <div style="text-align: right;">
              <el-button type="primary" size="small" @click="stabilization(handleLog)" class="dataBtn"
                :disabled="!(logStartTime && logStartHour && logEndTime && logEndHour)" @keyup.space.prevent.native @keydown.enter.prevent.native>
                {{ $t("robot.obtain") }}
              </el-button>
            </div>

            <p v-if="(type === 'ros' && logUrlRos !== '') || (type === 'nav' && logUrlNav !== '')"
              class="logDownloadBox">
              <span>{{ type === 'ros' ? currentLogTimeRos : currentLogTimeNav }}</span>
              &emsp; {{ $t("robot.obtainSucess") }}&emsp;
              <a :href="type === 'ros' ? logUrlRos : logUrlNav" target="_blank" class="downloadBtn">{{
                  $t("robot.clickDownload")
              }}</a>
            </p>
            <p v-else-if="(type === 'ros' && currentLogTimeRos !== '') || (type === 'nav' && currentLogTimeNav !== '')"
              class="logDownloadBox">
              <span>{{ type === 'ros' ? currentLogTimeRos : currentLogTimeNav }}</span>
              <span class="errorTip">&emsp; {{ $t('robotInfo.log.getFail') }}&emsp; </span>
            </p>
          </div>
        </div>
        <div class="progressc" v-show="loading">
          <el-progress :text-inside="true" :stroke-width="16" :percentage="percentage" v-show="percentage>0" />
          <div v-show="percentage==0" :style="{textAlign:'center',color:'#1890FF'}">{{$t('robotInfo.log.packing')}}...</div>
        </div>
        <div v-show="loading" class="progresscBtn">
          <sapn v-loading="cancelLaoding"></sapn>
          <el-button type="primary" size="small" @click="cancelLog" v-show="!cancelLaoding" @keyup.space.prevent.native @keydown.enter.prevent.native>
            {{ $t("system.cancel") }}
          </el-button>
        </div>
      </el-dialog>
      <el-dialog v-model="removetimingtaskfalge" :close-on-press-escape="false" :close-on-click-modal="false"
        :title="$t('disinfect.sccdsrw')" width="30%" center :show-close="false">
        <div class="algincenter">
          {{ $t("disinfect.qrsccdsrw") }}
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="stabilization(canceltingflage)" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
                $t("system.cancel")
            }}</el-button>
            <el-button type="primary" @click="stabilization(reomovedingshitask)" @keyup.space.prevent.native @keydown.enter.prevent.native>{{ $t("system.determine") }}
            </el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog v-model="docktask" :close-on-press-escape="false" :close-on-click-modal="false"
        :title="$t('task.yjhz')" width="30%" center :show-close="false">
        <div class="algincenter">
          {{ dockcontent }}
        </div>
        <template #footer>
          <span class="dialog-footer">
            <!-- <el-button @click="centerDialogVisible = false">Cancel</el-button> -->
            <el-button type="primary" @click="stabilization(Shutdowndock)" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
                dockconfim
            }}</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog v-model="chongqifalge" :close-on-press-escape="false" :close-on-click-modal="false"
        :title="chongqititle" width="30%" center :show-close="false">
        <div class="algincenter">
          {{ chongqitext }}
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="chongqifalge = false" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
                $t("system.cancel")
            }}</el-button>
            <el-button type="primary" @click="stabilization(chongqioffs)" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
                $t("system.determine")
            }}</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog v-model="turnfalge" :close-on-press-escape="false" :close-on-click-modal="false" :title="guanjititle"
        width="30%" center :show-close="false">
        <div class="algincenter">
          {{ guanjitext }}
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="turnfalge = false" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
                $t("system.cancel")
            }}</el-button>
            <el-button type="primary" @click="stabilization(turnoffs)" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
                $t("system.determine")
            }}</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog v-model="editRobotNameVisable" :title="$t('robot.changeNameTitle')" width="30%"
        @close="closeNicknameDialog">
        <el-form :model="nicknameForm" ref="nicknameFormRef">
          <el-form-item label="" prop="nickname">
            <el-input v-model="nicknameForm.nickname" autocomplete="off"
              :placeholder="$t('system.password.pleaseEnter')" maxlength="22" clearable />
            <el-button type="text" @click="nicknameForm.nickname = info.hint_nickname" @keyup.space.prevent.native @keydown.enter.prevent.native>
              {{ $t('robot.useOriName') }}
            </el-button>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="closeNicknameDialog" @keyup.space.prevent.native @keydown.enter.prevent.native>{{ $t("system.cancel") }}</el-button>
            <el-button type="primary" @click="changeRobotName" :disabled="nicknameForm.nickname.trim() == ''" @keyup.space.prevent.native @keydown.enter.prevent.native>{{
                $t("system.determine")
            }}
            </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/bread-crumb/Index.vue";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
  onBeforeUnmount,
  toRefs,
  unref,
} from "vue";
import {
  getRobotDetail,
  taskList,
  getLog,
  resetLogin,
  logData,
  checkToken,
  robotDni,
  changeRobot,
  delRobot,
  cancelLogReq,
} from "@/apis/robot";
import {
  createTasks,
  Operationtasks,
  deletetaskrw,
  scheduledTasks,
  removetimingtask,
  createdockdetail,
  refreshrobt,
  robotrbts,
  turnOff
} from "@/apis/region";
import {
  useRouter,
  useRoute
} from "vue-router";
import {
  ElMessageBox,
  ElMessage
} from "element-plus";
import { useStore } from "@/store";
import { UserActionTypes } from '@/store/modules/user/action-types'
import { ElForm } from 'element-plus';
import moment from "moment";
import {
  getHM,
  totalImg,
  setRobotName,
  disinfectType,
  momentStamp,
} from "@/utils/robot";
// 引入防抖 节流
import {
  debounce,
  throttle
} from "lodash";
import i18n from "@/locales";
import {
  stat
} from "fs";
import { getHardwareJson, hourList } from "@/utils/constant"
import { getLanguage } from "@/utils/cookies";
const _t = i18n.global.t;
let websocket = null;
export default {
  components: {},
  setup () {
    const router = useRouter();
    const route = useRoute();
    const nicknameFormRef = ref(ElForm)
    const state = reactive({
      sn: "",
      total: 0,
      info: {},
      curLanguage:getLanguage()||'en',
      totalImg: totalImg(),
      momentStamp: momentStamp,
      setRobotName: setRobotName,
      getHM: getHM,
      disinfectType: disinfectType,
      moment: moment,
      activeName: "1",
      total: 6,
      tableData: [],
      websocketUrl: process.env.VUE_APP_WEBSOCKET_URL,
      params: {
        page: 1,
        pageSize: 6,
        limit: true,
        sn: "",
      },
      cancelLaoding:false,
      dialogVisible: false,
      type: "ros",
      // timeList: [],
      // time: 1,
      logStartTime: null,
      logEndTime: null,
      logStartHour: null,
      logEndHour: null,
      // uuid: '',
      hourList: hourList(),
      loading: false,
      currentLogTimeRos: "",
      currentLogTimeNav: "",
      logUrlRos: "",
      logUrlNav: "",
      percentage: 0,
      Noteven: false,
      docktask: false,
      dockcontent: "回桩状态",
      dockconfim: _t("system.determine"),
      dockstatus: null,
      timingtaskdetail: null,
      timingindex: null,
      tableDatatiming: [],
      removetimingtaskfalge: false,
      turnfalge: false,
      chongqifalge: false,
      guanjitext: '',
      guanjititle: '',
      chongqitext: '',
      chongqititle: '',
      dniParams: {},
      editRobotNameVisable: false,
      nicknameForm: {
        nickname: '',
      },
      newintertime: null,
      interval: null,
      disabledDate (time) {
        return state.logEndTime ? time.getTime() > state.logEndTime : time.getTime() > moment().subtract(0, 'days')
      },
      disabledDate2 (time) {
        return state.logStartTime ?
          state.logStartTime > time.getTime() || time.getTime() > moment().subtract(0, 'days')
          : time.getTime() > moment().subtract(0, 'days')
      },
      //跳转机器人列表
      gotorobotlists() {
        console.log('123','点击机器人')
         router.push({
          path:'/robot/robotList'
         })
      },
      setcharges (chargess = 0, leves = 0) {
        if (chargess === 1) {
          if (leves < 100) {
            return _t('disinfect.xiancz')
          } else {
            return _t('disinfect.xiancycm')
          }
        } else if (chargess === 2) {
          if (leves < 100) {
            return _t('disinfect.zczhong')
          } else {
            return _t('disinfect.zczhongycm')
          }
        } else {
          return ''
        }
      },
      chongqifalgechaxun () { // 重启
        if (state.info.status === "leave") {
          ElMessage({
            message: _t('disinfect.jqrbsydqqyh'),
          });
          return;
        }
        if (!state.info.online) {
          ElMessage({
            message: _t("robot.robotOfflineNo"),
          });
          return;
        }
        robotrbts(route.params.sn).then(rbtres => {
          console.log(JSON.parse(rbtres.data))
          if (JSON.parse(rbtres.data).content.priority_lock === 1) {
            ElMessage({
              message: _t('disinfect.jtzbyxccz'),
            });
            return;
          } else if (JSON.parse(rbtres.data).content.priority_lock === 2) {
            ElMessage({
              message: _t('disinfect.cdzbyxccz'),
            });
            return
          } else if (JSON.parse(rbtres.data).content.status != 'idle') {
            ElMessage({
              message: _t('disinfect.rwzbyxczz'),
            });
          } else {
            state.chongqitext = _t('disinfect.qrhjqrjcq')
            state.chongqititle = _t('disinfect.jqrcq')
            state.chongqifalge = true
          }
        })
      },
      turnfalgechaxun () { // 关机
        if (state.info.status === "leave") {
          ElMessage({
            message: _t('disinfect.jqrbsydqqyh'),
          });
          return;
        }
        if (!state.info.online) {
          ElMessage({
            message: _t("robot.robotOfflineNo"),
          });
          return;
        }
        robotrbts(route.params.sn).then(rbtres => {
          console.log(JSON.parse(rbtres.data))
          if (JSON.parse(rbtres.data).content.priority_lock === 1) {
            ElMessage({
              message: _t('disinfect.jtzbyxccz'),
            });
            return;
          } else if (JSON.parse(rbtres.data).content.priority_lock === 2) {
            ElMessage({
              message: _t('disinfect.cdzbyxccz'),
            });
            return
          } else if (JSON.parse(rbtres.data).content.status != 'idle') {
            ElMessage({
              message: _t('disinfect.rwzbyxczz'),
            });
          } else {
            state.guanjitext = _t('disinfect.qdhjgj')
            state.guanjititle = _t('disinfect.jqrgj')
            state.turnfalge = true
          }
        })
      },
      // 同步状态更新
      syncstatus () {
        if (state.info.status === "leave") {
          ElMessage({
            message: _t('disinfect.jqrbsydqqyh'),
          });
          return;
        }
        if (!state.info.online) {
          ElMessage({
            message: _t("robot.robotOfflineNo"),
          });
          return;
        }
        refreshrobt({
          sn: route.params.sn
        }).then(res => {
          console.log(res)
          if (res.code === 200) {
            ElMessage({
              message: _t("disinfect.tbztcg"),
              type: 'success'
            });
            state.getList();
            state.getTaskList();
            //  state.getTimeList();
            state.getscheduledTask();
          }
        })
      },
      // 点击进入定时任务修改详情页
      gotoediting (row) {
        robotrbts(route.params.sn).then(rbtres => {
          if (JSON.parse(rbtres.data).content.priority_lock === 1) {
            ElMessage({
              message: _t('disinfect.jtzbyxccz'),
            });
            return
          }
        })
        router.push(`/robot/EditingScheduled/${route.params.sn}/${row.name}/${row.type}`);
      },
      goDisinfect () {
        router.push({
          name: "disinfect",
          params: {
            sn: route.params.sn
          },
        });
      },
      // 删除定时任务弹框
      removetimingtask (index, rows) {
        console.log(index, rows);
        robotrbts(route.params.sn).then(rbtres => {
          if (JSON.parse(rbtres.data).content.priority_lock === 1) {
            ElMessage({
              message: _t('disinfect.jtzbyxccz'),
            });
          } else {
            state.timingtaskdetail = rows;
            state.timingindex = index;
            state.removetimingtaskfalge = true;
          }
        })
      },
      // 取消删除定时任务并清空参数
      canceltingflage () {
        state.timingtaskdetail = null;
        state.timingindex = null;
        state.removetimingtaskfalge = false;
      },
      // 这一步是真正的删除 删除完以后再调一次定时任务列表接口
      reomovedingshitask () {
        console.log(state.timingtaskdetail);
        removetimingtask({
          sn: route.params.sn,
          taskid: state.timingtaskdetail[state.timingindex].name,
        }).then((res) => {
          if (JSON.parse(res.data).content.code === 0) {
            state.removetimingtaskfalge = false;
            state.getscheduledTask();
          }
        });
      },
      // 创建定时任务 离线时统一不允许操作
      async addtimingtasks () {
        if (state.info.status === "leave") {
          ElMessage({
            message: _t('disinfect.jqrbsydqqyh'),
          });
          return;
        }
        if (!state.info.online) {
          ElMessage({
            message: _t("robot.robotOfflineTip1"),
          });
          return;
        }
        await state.getscheduledTask();
        if (state.tableDatatiming.length >= 20) {
          ElMessage({
            message: _t('disinfect.zdszwgrw'),
          });
          return
        }
        robotrbts(route.params.sn).then(rbtres => {
          if (JSON.parse(rbtres.data).content.priority_lock === 1) {
            ElMessage({
              message: _t('disinfect.jtzbyxccz'),
            });
            return
          } else {
            router.push(`/robot/timingdetail/${route.params.sn}`);
          }
        })
      },
      getList () {
        const {
          params
        } = route;
        // console.log('params=>', params)
        getRobotDetail(params).then((res) => {
          if (res.code == 200) {
            const obj = res.data;
            if (obj.door_sensor) {
              obj.door_sensor = obj.door_sensor;
            }
            if (obj.version) {
              obj.version = JSON.parse(obj.version);
            }
            obj.battery_info ? obj.battery_info = JSON.parse(obj.battery_info) : ''
            state.info = obj;
            console.log('inro=>', obj)
          }
        });
      },
      getDni () {
        const params = { sn: route.params.sn }
        robotDni(params).then((res) => {
          if (res.code == 200 && res.data.length > 0) {
            const baselist = getHardwareJson().deviceList.map(item => item.key)
              .concat(getHardwareJson().sensorList.map(item => item.key))
              .concat(getHardwareJson().systemList.map(item => item.key))
            const newlist = res.data.filter(item => baselist.indexOf(item.key) >= 0)
            const systemlist = newlist.filter(item => item.key.toString()[0] === '1') // 系统
            const devicelist = newlist.filter(item => item.key.toString()[0] === '2') // 设备
            const sensorlist = newlist.filter(item => item.key.toString()[0] === '4' || item.key.toString()[0] === '5') // 传感器
            const isSystem = systemlist.filter(item => item.error_value !== 0).length == 0
            const isDevice = devicelist.filter(item => item.error_value !== 0).length == 0
            const isSensor = sensorlist.filter(item => item.error_value !== 0).length == 0
            const normalNum = newlist.filter(item => item.error_value === 0).length
            const errorNum = newlist.filter(item => item.error_value !== 0).length
            state.dniParams = {
              systemlist,
              devicelist,
              sensorlist,
              isSystem,
              isDevice,
              isSensor,
              normalNum,
              errorNum,
            }
          }
        })
      },
      handleLog () {
        const {
          type,
          logStartTime,
          logEndTime,
          logStartHour,
          logEndHour,
        } = state;
        const logid = Number(state.rnd(new Date().getTime())) * 1
        const params = {
          sn: state.params.sn,
          type,
          start: logStartTime.getTime() / 1000 + Number(logStartHour) * 3600,
          end: logEndTime.getTime() / 1000 + Number(logEndHour) * 3600,
          logid,
        }
        sessionStorage.setItem('hsaloguuid', logid)
        state.loading = true
        getLog(params)
          .then((res) => {
            if (res.code == 200) {
              state.loading = true;
              state.logid = logid * 1
              state.percentage = 0;
          state.newintertime =  setInterval(() => {
             const params1 = {
              robotsn: state.params.sn,
               topic: "notify",
               unsubscribe: true,
           };
              const val1 = JSON.stringify(params1);
              websocket.send(val1);
              const params2 = {
              robotsn: state.params.sn,
               topic: "notify",
               unsubscribe: false,
           };
              const val2 = JSON.stringify(params2);
              setTimeout(() => {
                 websocket.send(val2);
              }, 10);
              }, 5000);
              state.setLimitTime()
            } else if (res.code == -2) {
              state.loading = false;
              ElMessage({
                type: "error",
                message: _t('robotInfo.log.hasNoLog'),
              });
            } else if (res.code == -3) {
              state.loading = false;
              ElMessage({
                type: "error",
                message: _t('robotInfo.log.pleaseselect'),
              });
            } else {
              state.loading = false;
              state.sendGetFail()
            }
          })
          .catch((err) => {
            ElMessage.closeAll();
            state.loading = false;
            state.sendGetFail()
          });
      },
      cancelLogDialog () {
        state.dialogVisible = false;
        state.logStartTime = undefined;
        state.logEndTime = undefined;
        state.logStartHour = undefined;
        state.logEndHour = undefined;
        // state.loading = false;
      },
      cancelLog () {
        // 取消获取日志
        state.cancelLogRequest()
        clearInterval(state.newintertime)
        clearTimeout(state.interval)
      },
      cancelLogRequest () { // 取消获取日志调接口
        const params = {
          logid: state.logid * 1,
          sn: state.params.sn,
        }
        // state.loading = false
        state.cancelLaoding = true
        cancelLogReq(params).then(res => {
          state.loading = false
          state.cancelLaoding = false
        }).catch(err => {
          state.loading = false
          state.cancelLaoding = false
        })
      },
      getLogType (val) {
        state.type = val;
      },
      getLogBox () {
        //  if (state.info.status === "leave") {
        //   ElMessage({
        //     message: _t('disinfect.jqrbsydqqyh'),
        //   });
        //   return;
        // }
        // if (!state.info.online) {
        //   ElMessage({
        //     message: _t("robot.robotOfflineTip1"),
        //   });
        //   return;
        // }
        state.dialogVisible = true;
        const params = {
          sn: state.params.sn,
        };
        logData(params)
          .then((res) => {
            if (res.code == 200) {
              let val = res.data,
                url;
              if (val && val.status == "finish") {
                state.type = val.log_type;
                if (state.type === 'ros') {
                  state.logUrlRos = val.url
                  state.currentLogTimeRos = moment(val.updated_at * 1000).format(momentStamp('datetime'))
                } else {
                  state.logUrlNav = val.url
                  state.currentLogTimeNav = moment(val.updated_at * 1000).format(momentStamp('datetime'))
                }
              } else if (val && val.status === 'failed') {
                state.type = val.log_type;
                if (state.type === 'ros') {
                  state.logUrlRos = ''
                  state.currentLogTimeRos = moment(val.updated_at * 1000).format(momentStamp('datetime'))
                } else {
                  state.logUrlNav = ''
                  state.currentLogTimeNav = moment(val.updated_at * 1000).format(momentStamp('datetime'))
                }
              } else {
                // state.sendGetFail()
              }
            }
          })
          .catch((err) => {
            state.sendGetFail()
          });
      },
      // getLogTime(val) {
      //   state.time = val;
      // },
      sendGetFail () {
        ElMessage({
          type: "error",
          message: _t('robotInfo.log.getFail'),
        });
      },
      closeGetLog () {
        clearInterval(state.newintertime)
        state.loading = false
        state.percentage = 0;
        clearTimeout(state.interval)
      },
      setFailed () {
        state.type === 'ros' ? state.logUrlRos = '' : state.logUrlNav = ''
        state.type === 'ros' ? state.currentLogTimeRos = moment().format(
          "YYYY-MM-DD HH:mm:ss"
        ) : state.currentLogTimeNav = moment().format(
          "YYYY-MM-DD HH:mm:ss"
        )
      },
      setLimitTime () {
        clearTimeout(state.interval)  // 先清除
        state.interval = setTimeout(function () {
          console.log('超5分钟未接收到消息')
          state.setFailed()
          state.closeGetLog()
          state.sendGetFail()
          state.cancelLogRequest()
        }, 60000*5)
      },
      // 创建一键回桩
      createddock () {
        if (state.info.status === "leave") {
          ElMessage({
            message: _t('disinfect.jqrbsydqqyh'),
          });
          return;
        }
        if (!state.info.online) {
          ElMessage({
            message: _t("robot.robotOfflineNo"),
          });
          return;
        }
        const restask = {
          area_id: 0,
          auto_finish: 0,
          countdown: 0,
          cycle: "immediately",
          date: "string",
          days: ["string"],
          duration: 0,
          flags: 0,
          map_name: "string",
          room_id: 0,
          time: "string",
          type: "dock",
        };
        console.log(restask);
        createdockdetail(route.params.sn, restask).then((res) => {
          // console.log(res)
          Operationtasks(res.data.taskid, "start", state.params.sn, {}).then(
            (ress) => {
              console.log(ress);
              if (JSON.parse(ress.data).content.code === 0) {
                state.docktask = true;
                state.dockcontent = _t("disinfect.zzsz");
                state.dockconfim = _t("disinfect.qxsz");
              } else if (JSON.parse(ress.data).content.code === 11010031) {
                ElMessage({
                  message: _t("creted.mjpwljcjsb"),
                });
                deletetaskrw(res.data.taskid, {});
              } else if (JSON.parse(ress.data).content.code === 11010023) {
                ElMessage({
                  message: _t("creted.dqyjxrw"),
                });
                deletetaskrw(res.data.taskid, {});
              } else if (JSON.parse(ress.data).content.code === 11010028) {
                ElMessage({
                  message: _t("creted.xtlbcwcjsb"),
                });
                deletetaskrw(res.data.taskid, {});
              } else if (JSON.parse(ress.data).content.code === 11010032) {
                ElMessage({
                  message: _t("creted.sbgzcjsb"),
                });
                deletetaskrw(res.data.taskid, {});
              } else if (JSON.parse(ress.data).content.code === 11010030) {
                ElMessage({
                  message: _t("creted.jqjtqbcan"),
                });
                deletetaskrw(res.data.taskid, {});
              } else if (JSON.parse(ress.data).content.code === 11010033) {
                ElMessage({
                  message: _t("creted.dcyccjsb"),
                });
                deletetaskrw(res.data.taskid, {});
              } else if (JSON.parse(ress.data).content.code === 11010034) {
                ElMessage({
                  message: _t("creted.fztyccjsb"),
                });
                deletetaskrw(res.data.taskid, {});
              } else if (JSON.parse(ress.data).content.code === 11010035) {
                ElMessage({
                  message: _t("creted.dpdjglcjsb"),
                });
                deletetaskrw(res.data.taskid, {});
              } else if (JSON.parse(ress.data).content.code === 11010036) {
                ElMessage({
                  message: _t("creted.zdyccjsb"),
                });
                deletetaskrw(res.data.taskid, {});
              } else if (JSON.parse(ress.data).content.code === 11010037) {
                ElMessage({
                  message: _t("creted.zczcjsb"),
                });
                deletetaskrw(res.data.taskid, {});
              } else if (JSON.parse(ress.data).content.code === 11010038) {
                ElMessage({
                  message: _t("creted.xczcjsb"),
                });
                deletetaskrw(res.data.taskid, {});
              } else if (JSON.parse(ress.data).content.code === 11010042) {
                ElMessage({
                  message: _t("creted.dqdtbczwfcj"),
                });
                deletetaskrw(res.data.taskid, {});
              } else if (JSON.parse(ress.data).content.code === 11010045) {
                ElMessage({
                  message: _t("creted.dhmkyxzcjsb"),
                });
                deletetaskrw(res.data.taskid, {});
              } else if (JSON.parse(ress.data).content.code === 11010046) {
                ElMessage({
                  message: _t("creted.cgqyccjsb"),
                });
                deletetaskrw(res.data.taskid, {});
              } else if (JSON.parse(ress.data).content.code === 11010048) {
                ElMessage({
                  message: _t("creted.jqrwdggcjsb"),
                });
                deletetaskrw(res.data.taskid, {});
              } else if (JSON.parse(ress.data).content.code === 11010049) {
                ElMessage({
                  message: _t("creted.jqrdlxy"),
                });
                deletetaskrw(res.data.taskid, {});
              } else {
                deletetaskrw(res.data.taskid, {});
              }
            }
          );
        });
      },
      getTaskList () {
        const {
          params
        } = route;
        state.params.sn = params.sn;
        taskList(state.params).then((res) => {
          if (res.code == 200) {
            state.tableData = res.data.list;
            state.total = res.data.total;
          }
        });
      },
      goTaskInfo (row) {
        localStorage.setItem("taskInfo", JSON.stringify(row));
        router.push(`/robot/taskInfo/${row.report}`);
      },
      gocretedDetail () {
        if (state.info.status === "leave") {
          ElMessage({
            message: _t('disinfect.jqrbsydqqyh'),
          });
          return;
        }
        if (!state.info.online) {
          ElMessage({
            message: _t("robot.robotOfflineNo"),
          });
          return;
        }
        robotrbts(route.params.sn).then(rbtres => {
          if (JSON.parse(rbtres.data).content.status != 'idle') {
            ElMessage({
              message: _t('disinfect.rwzbyxczz'),
            });
            return
          } else if (JSON.parse(rbtres.data).content.priority_lock === 1) {
            ElMessage({
              message: _t('disinfect.jtzbyxccz'),
            });
            return
          } else {
            router.push(`/robot/taskstatus/${route.params.sn}`);
          }
        })
      },
      // 防抖
      stabilization: debounce((Needtooffer) => Needtooffer(), 1000, {
        leading: true,
        trailing: false,
      }),
      turnoffs () {
        turnOff(route.params.sn, {
          // compulsive: true,
          mode: 1,
        }).then((res) => {
          console.log(res);
          state.turnfalge = false
        });
      },
      chongqioffs () {
        turnOff(route.params.sn, {
          // compulsive: true,
          mode: 0,
        }).then((res) => {
          console.log(res);
          state.chongqifalge = false
        });
      },
      handleCurrentChange (page) {
        state.params.page = page;
        state.getTaskList();
      },
      getVersion (obj) {
        let arr = [],
          str = "";
        for (const i in obj) {
          // if (i == "total") {
          //   const list = {
          //     key: _t("robot.app_edition"),
          //     val: obj[i],
          //   };
          //   arr.unshift(list);
          // }
          // else {
          const list = {
            key: i,
            val: obj[i],
          };
          arr.push(list);
          // }
        }
        arr.map((item) => {
          str += `<div class="flexrleft"><div class="flexrleftkey">${item.key}</div><div class="flexrleftval">${item.val}</div></div>`;
        });
        ElMessageBox.alert(str, _t("robot.robotEdition"), {
          dangerouslyUseHTMLString: true,
          confirmButtonText:_t('system.determine')
        });
      },
      getDoorStatus (val, n, type) {
        let color, text;
        if (!n) {
          color = "red";
          text = _t("robot.unpaired");
        } else {
          switch (val) {
            case "disconnect":
              color = "#999";
              text = _t("robot.notConnected");
              break;
            default:
              color = "#4caf50";
              text = _t("robot.connected");
              break;
          }
        }
        return type == 0 ? color : text;
      },
      handleSearch () {
        state.params.page = 1;
        state.getList();
      },
      changeLog (type) {
        state.type = type;
      },
      getRobotDetail (item) {
        router.push(`/robot/robotInfo/${item.sn}`);
      },
      // 调用取消获取日志给到机器人，取消继续上传，loading=false
      openWebsocket () {
        const params = {
          robotsn: state.params.sn,
          topic: "notify",
          unsubscribe: false,
        };
        console.log(params)
        const val = JSON.stringify(params);
        if ("WebSocket" in window) {
          // websocket = new WebSocket(state.websocketUrl)

          websocket = new WebSocket(
            state.websocketUrl + "?token=" + useStore().state.user.token
          );
        } else {
          // console.log('Not support websocket')
        }
        //成功回调
        websocket.onopen = function (event) {
          console.log("机器人详情websocket连接成功！");
          websocket.send(val);
          console.log("val=>", val);
        };
        //事件监听
        websocket.onmessage = function (event) {
          const { params } = route
          const robotSn = params.sn
          if (event.data == "ping") {
            websocket.send("pong");
            return;
          }
          let data = {};
          const dataType = typeof event.data == "string";
          if (event.data && dataType) {
            console.log('event.data>>>', event.data)
            data = JSON.parse(event.data);
          }
          console.log("机器人详情event=>", data);
          // 获取日志
          if (data.content && data.title == "notify_file_upload") { // notify_file_upload
          console.log(sessionStorage.getItem('hsaloguuid'),data.content.id,data.content.id==sessionStorage.getItem('hsaloguuid'))
            if (sessionStorage.getItem('hsaloguuid')&&sessionStorage.getItem('hsaloguuid')==data.content.id){
            if (data.content.status === 'failed') {
              state.closeGetLog()
              state.sendGetFail()
              state.type === 'ros' ? state.logUrlRos = '' : state.logUrlNav = ''
              state.type === 'ros' ? state.currentLogTimeRos = moment(data.content.timestamp).format(
                "YYYY-MM-DD HH:mm:ss"
              ) : state.currentLogTimeNav = moment(data.content.timestamp).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            } else if (data.content.status === 'finish') {
              state.closeGetLog()
              state.type === 'ros' ? state.logUrlRos = data.content.url : state.logUrlNav = data.content.url
              state.type === 'ros' ? state.currentLogTimeRos = moment(data.content.timestamp).format(
                "YYYY-MM-DD HH:mm:ss"
              ) : state.currentLogTimeNav = moment(data.content.timestamp).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            } else {
              state.percentage = data.content.persentage;
              state.loading = true;

              state.setLimitTime() // 开始倒计时
              state.logid = data.content.uuid * 1
            }
            }
          }
          if (                                                      // notify_task_status
            data.title === "notify_task_status" &&
            data.content.type === "dock"
          ) {
            state.dockstatus = data.content;
            state.dockcontent = _t("disinfect.zzsz");
            state.dockconfim = _t("disinfect.qxsz");
            if (
              state.dockstatus.status === "canceled" ||
              state.dockstatus.status === "failed"
            ) {
              state.dockconfim = _t("disinfect.gb");
              state.dockcontent = state.formsendstatus(state.dockstatus);
            } else if (state.dockstatus.status === "finish") {
              state.dockconfim = _t("disinfect.gb");
              state.dockcontent = _t("disinfect.szcg");
            } else if (state.dockstatus.status === "start") {
              state.dockconfim = _t("disinfect.qxsz");
              if (state.dockstatus.phase === null) {
                return;
              }
              if (state.dockstatus.phase.type === "dock") {
                state.dockcontent = _t("disinfect.zzsz");
              } else if (state.dockstatus.phase.type === "navigation_dock") {
                state.dockcontent = _t("disinfect.dhqwcdz");
              }
            } else {
              state.dockconfim = _t("disinfect.qxsz");
            }
          }
          if (data.content && data.title == "response_get_system_info") { // response_get_system_info
            state.info.online = data.content.online;
            state.info.battery = data.content.battery;
            state.info.battery_info = data.content.battery_info ? JSON.parse(data.content.battery_info) : '';
            state.info.net_type = data.content.net_type;
            state.info.stat_count = data.content.stat_count;
            state.info.stat_time = data.content.stat_time;
            state.info.door_sensor = data.content.door_sensor;
          }
        };
        websocket.onclose = function () {
          console.log("机器人详情websocket关闭！");
          if (state.Noteven) {
            console.log("我已经关闭了websocket");
            return;
          }
          state.openWebsocket();
          checkToken().then().catch((err) => {
            const val = err.response.data;
            if (val.code == 401) {
              useStore().dispatch(UserActionTypes.ACTION_LOGIN_OUT)
              router.push('/login')
              ElMessage({
                type: "error",
                message: _t('system.loginTimeout'),
              });
            }
          });
        };
      },
      Shutdowndock () {
        if (
          state.dockstatus.status === "finish" ||
          state.dockstatus.status === "canceled" ||
          state.dockstatus.status === "failed"
        ) {
          state.docktask = false;
          return;
        }
        Operationtasks(
          state.dockstatus.name,
          "terminate",
          state.params.sn, {}
        ).then((res) => {
          state.docktask = false;
        });
      },
      formsendstatus: (detailStatus) => {
        console.log(detailStatus);
        if (detailStatus.detail === "safeguard") {
          return _t("minbox.aqsjzt");
        } else if (detailStatus.detail === "manual") {
          return _t("minbox.sdjieshu");
        } else if (detailStatus.detail === "access_control_stop") {
          return _t("minbox.mjtz");
        } else if (detailStatus.detail === "push_on") {
          return _t("minbox.stchufa");
        } else if (detailStatus.detail === "safeguard_pir") {
          return _t("minbox.pirjs");
        } else if (detailStatus.detail === "safeguard_ai") {
          return _t("minbox.sxtjs");
        } else if (detailStatus.detail === "safeguard_access_control") {
          return _t("minbox.mjjs");
        } else if (detailStatus.detail === "access_control") {
          return _t("minbox.mjdl");
        } else if (detailStatus.detail === "geomagnetic") {
          return _t("minbox.dici");
        } else if (detailStatus.detail === "anticollision") {
          return _t("minbox.fangzt");
        } else if (detailStatus.detail === "chassis") {
          return _t("minbox.dlguoji");
        } else if (detailStatus.detail === "fall_into_cliff_danger") {
          return _t("minbox.xyjc");
        } else if (
          detailStatus.detail === "device_error" ||
          detailStatus.detail === "diagnosis"
        ) {
          return _t("minbox.sbgz");
        } else if (
          detailStatus.detail === "fail_zero_arrived" ||
          detailStatus.detail === "fail_target_point_unreachable" ||
          detailStatus.detail === "fail_uslam_unable_navigation" ||
          detailStatus.detail === "fail_uslam_navi_no_way"
        ) {
          return _t("minbox.daohangshibai");
        } else if (detailStatus.detail === "fail_relocation_faild") {
          return _t("minbox.dingweishibai");
        } else if (
          detailStatus.detail === "fail_uslam_unable_auto_mapping" ||
          detailStatus.detail === "fail_fov_not_fit"
        ) {
          return _t("minbox.jiantushibai");
        } else if (detailStatus.detail === "fail_leave_dock") {
          return _t("minbox.xiazhuangsb");
        } else if (detailStatus.detail === "fail_dock") {
          return _t("minbox.shangzhuangsb");
        } else if (detailStatus.detail === "sensor_error") {
          return _t("minbox.cgqyc");
        } else if (detailStatus.detail === "fail_slam_json_error") {
          return _t("minbox.dhcscw");
        } else if (detailStatus.detail === "fail_slam_http_error") {
          return _t("minbox.dhfwqcw");
        } else if (detailStatus.detail === "fail_target_point_unreachable") {
          return _t("minbox.mbdbkd");
        } else if (detailStatus.detail === "fail_uslam_not_init") {
          return _t("minbox.dhmkcshsb");
        } else if (detailStatus.detail === "fail_dock_recognise") {
          return _t("minbox.zbdcdz");
        } else if (detailStatus.detail === "fail_interrupt_abnormal") {
          return _t("minbox.yczd");
        } else {
          return _t("minbox.qita");
        }
      },
      getscheduledTask: () => {
        scheduledTasks({
          sn: state.params.sn,
          pageSize: 30,
          page: 0,
        }).then((res) => {
          console.log(JSON.parse(res.data).content.list);
          state.tableDatatiming = JSON.parse(res.data).content.list;
          state.tableDatatiming.forEach((ele, index) => {
            ele.cycle === "once" ?
              (ele["runupTime"] = ele.date + " " + ele.time) :
              ele.cycle === "week" ?
                (ele["runupTime"] =
                  state.filtrationweek(ele.days) + " " + ele.time) :
                ele.cycle === "day" ? ele["runupTime"] =
                  _t("disinfect.days") + " " + ele.time : '';
            // ele.type === "explore_disinfect" ?
            //   (ele["newtype"] = _t("creted.zztansuo")) :
            //   (ele["newtype"] = _t("robot.deploy_disinfect"));
          });
        });
      },
      filtrationweek (weekdays) {
        if (weekdays.length >= 7) {
          return _t("disinfect.days");
        }

        function daystext (daymath) {
          if (daymath === 0) {
            return " " + _t("creted.zhouri");
          } else if (daymath === 1) {
            return " " + _t("creted.zhouyi");
          } else if (daymath === 2) {
            return " " + _t("creted.zhouer");
          } else if (daymath === 3) {
            return " " + _t("creted.zhousan");
          } else if (daymath === 4) {
            return " " + _t("creted.zhousi");
          } else if (daymath === 5) {
            return " " + _t("creted.zhouwu");
          } else if (daymath === 6) {
            return " " + _t("creted.zhouliu");
          }
        }
        let forweek = "";
        weekdays.forEach((eles, index) => {
          forweek += daystext(eles);
        });
        return forweek;
      },
      resetLoginPwd () {
        if (state.info.status === "leave") {
          ElMessage({
            message: _t('disinfect.jqrbsydqqyh'),
          });
          return;
        }
        if (!state.info.online) {
          ElMessage.closeAll();
          ElMessage({
            message: _t("robot.robotOfflineNo"),
          });
          return;
        }
        ElMessageBox.confirm(_t("robot.resetSure"), _t("robot.resetLoginPwd"), {
          confirmButtonText: _t("system.reset"),
          cancelButtonText: _t("system.cancel"),
          type: "warning",
        })
          .then(() => {
            const params = {
              sn: state.params.sn,
              data: {
                password: "123456",
                usertype: 'admin'
              }
            };
            resetLogin(params).then((res) => {
              if (res.code == 200) {
                ElMessageBox.alert(
                  _t("robot.resetLoginPwdTip"),
                  _t("robot.resetLoginPwdSuccess"), {
                  confirmButtonText: _t("system.determine"),
                }
                );
                state._handleSearch();
              }
            })
              .catch((err) => {
                const val = err.response.data;
                console.log("val=>", val);
                ElMessage.closeAll();
                ElMessage({
                  type: "error",
                  message: val.msg,
                });
              });
          })
      },
      openChangeName () {
        state.nicknameForm.nickname = state.info.nickname
        state.editRobotNameVisable = true
      },
      changeRobotName () {
        unref(nicknameFormRef).validate(async (valid) => {
          if (valid) {
            const params = {
              sn: state.params.sn,
              body: {
                nickname: state.nicknameForm.nickname.trim()
              }
            }
            changeRobot(params).then(res => {
              if (res.code === 200) {
                ElMessage({
                  type: 'success', message: _t('robot.changeNameSuccess')
                })
                state.closeNicknameDialog()
                state.getList()
              } else if (res.code == 10003) {
                ElMessage({
                  type: 'warning', message: _t('robot.robotNoExist')
                })
              } else {
                ElMessage({
                  type: 'warning', message: _t('robot.changeNameFail')
                })
              }
            }).catch(err => console.log(err.response.data))
          } else {
            return false
          }
        })
      },
      delRobotFunc () {
        ElMessageBox.confirm(_t('robot.delRobotTips'), _t('robot.delRobotTitle'), {
          confirmButtonText: _t('system.delete'),
          cancelButtonText: _t('system.cancel'),
          type: 'warning',
        })
          .then(() => {
            delRobot({ sn: state.params.sn, }).then(res => {
              if (res.code == 200) {
                ElMessage({
                  type: 'success',
                  message: _t('robot.delRobotDone')
                })
                router.push('/robot/robotList')
              } else if (res.code == 10011) {
                ElMessage({
                  type: 'warning',
                  message: _t('robot.delRobotFail')
                })
              } else if (res.code == 10003) {
                ElMessage({
                  type: 'warning',
                  message: _t('robot.robotNoExist')
                })
              }
            })
          })
          .catch((err) => {
            const val = err.response.data
            ElMessage.closeAll()
            ElMessage({
              type: 'error',
              message: _t('disinfect.czsbl')
            })
          })
      },
      closeNicknameDialog () {
        unref(nicknameFormRef).resetFields()
        state.editRobotNameVisable = false
      },
      rnd (seed) {
        seed = (seed * 9301 + 49297) % 233280;
        return String(seed / (233280.0)).slice(2, 10);
      },
    });
    onMounted(() => {
      state.getList();
      state.getTaskList();
      state.getscheduledTask();
      state.openWebsocket();
      state.getDni()
    });
    onBeforeUnmount(() => {
      // 离开此页面关闭websoket
      state.Noteven = true;
      websocket.close();
    });
    return {
      ...toRefs(state),
      debounce,
      nicknameFormRef,
      //   throttle
    };
  },
};

</script>

<style lang="scss">
.el-message-box {
  padding-bottom: 20px;
}

.taskList {
  tr th {
    background: rgba(250, 250, 250, 1);
  }
}

.flexrleft {
  display: flex;
  height: 30px;

  .flexrleftkey {
    width: 130px;
  }

  .flexrleftval {
    flex: 1;
  }
}

.algincenter {
  text-align: center;
}

.cursorpointers {
  cursor: pointer;
}

.addtimingtask {
  margin-top: 21px;
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .addImg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
}

.partContent {
  margin-top: 60px;

  .el-table__row {
    cursor: pointer;
  }

  .el-tabs__item {
    border-color: #d9d9d9 !important;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 0 14px !important;
    margin-right: 30px;
  }

  .el-tabs__item.is-active {
    color: #fff;
    background: #1890ff;
  }

  .el-tabs__nav-wrap::after {
    display: none !important;
  }

  .el-tabs__active-bar {
    display: none !important;
  }
}

.versionTip {
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .row {

    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    div {
      text-align: left;
    }
  }

  .infoLeft {
    flex-shrink: 0;
    display: inline-block;
    width: 100px;
    color: #000;
  }
  &.es .infoLeft{
    width:200px;
  }

  .infoRight {}
}
</style>
<style lang="scss" scoped>
.el-table .has-gutter .cell {
  // background: rgba(0, 0, 0, 0.05);
  // line-height: 45px;
}

.robotDetailBox {
  margin: 24px;
  position: relative;

  .robotDetail {
    margin-bottom: 16px;
    background: #fff;
    padding: 32px 24px;
    border-radius: 2px;

  }

  .taskCount {
    margin-bottom: 16px;
    background: #fff;
    border-radius: 2px;
    height: 154px;
    position: relative;

    .button {
      position: absolute;
      right: 24px;
      top: 24px;
      background: #FFFFFF;
      border: 1px solid #1890FF;
      color: rgba(24, 144, 255);

      &:hover {
        color: rgba(24, 144, 255, 1);
      }
    }

    .title {
      padding: 24px 43px;
    }

    .info {
      display: flex;
      flex-direction: row;
      padding-left: 69px;

      .info-item {
        display: flex;
        flex-direction: row;
        margin-right: 213px;

        img {
          width: 50px;
          height: 50px;
          margin-right: 16px;
        }

        .top {
          margin-bottom: 8px;
        }

        .bottom {
          font-size: 12px;
        }


      }
    }

  }

  .taskList {
    padding: 32px;
    background: #fff;
    border-radius: 2px;

    .title {
      padding-left: 11px;
      padding-bottom: 14px;
    }
  }

  .topMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .button {
      background: #FFFFFF;
      border: 1px solid #1890FF;
      color: rgba(24, 144, 255);

      &:hover {
        color: rgba(24, 144, 255, 1);
      }
    }
  }

  .robot {
    position: absolute;
    left: 0;
    top: 0;

    .robotImg {
      position: absolute;
      left: 68px;
      top: 96px;
      width: 98px;
      height: 174px;
    }

    .wifiStatus {
      position: absolute;
      left: 150px;
      top: 94px;
      background: #5BD171;
      border-radius: 60px;
      color: #fff;
      text-align: center;
      min-width: 70px;
      font-size: 12px;
      padding: 3px;
    }

    .cardStatus {
      position: absolute;
      left: 150px;
      top: 120px;
      min-width: 100px;
      padding: 2px 10px;
      background: #FFBF00;
      border-radius: 60px;
      color: #fff;
      text-align: center;
      font-size: 10px;
    }
  }

  .infoContent {
    min-height: 224px;
    margin-left: 255px;
    padding-top: 44px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .info-item {
      margin-left: 84px;

      .getlog {
        display: inline-block;
        font-size: 10px;
        padding: 3px 8px;
        border-radius: 3px;
        border: 1px solid #1890FF;
        margin-left: 12px;
        cursor: pointer;
        color: rgba(24, 144, 255);

        &:hover {
          color: rgba(24, 144, 255, 1);
        }
      }

      .info-robotName {
        font-size: 18px;
      }

      .editNameButton {
        color: #1890ff;
        margin: 0 0 0 10px;
        cursor: pointer;
      }

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;

        .infoLeft {
          // width:100px;
        }

        .infoImg {
          width: 17px;
          height: 17px;
          margin-left: 12px;
          cursor: pointer;
        }
      }

      .battery {
        width: 124px;
        height: 10px;
        background: #D8D8D8;
        border-radius: 2px;

        .batteryValue {
          height: 100%;
          border-radius: 2px;
        }

      }

      .baytteryPercent {
        margin-left: 10px;
      }
    }
  }

  .infoItem {
    padding-top: 20px;
    z-index: 999;

    p {
      span {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
      }

      span:nth-child(1) {
        width: 130px;
        padding-right: 20px;
        text-align: left;
        font-weight: bold;
      }
    }
  }

  .logitem {
    display: block;
    width: 150px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
  }

  .logActive {
    color: #1890ff;
    background: #e8f4fd;
  }

  .appstore {
    position: relative !important;
    width: 20px !important;
    cursor: pointer;
    top: 4px;
  }

  .logBox {
    position: relative;
    padding: 10px;
    min-height: 220px;
    white-space: nowrap;

    >div {
      display: inline-block;
      vertical-align: top;
    }

    // div:last-child {
    //   margin-left: 20px;
    // }
    .dateBox {
      height: 32px;
      margin: 0 10px;
      margin-left: 20px;

      .dataOneItem {
        margin: 10px 0;
        text-align: right;

        .startTime {
          margin: 0 10px;
        }

        .endTime {
          margin: 0 10px;
        }
      }
    }

    .logDownloadBox {
      position: relative;
      line-height: 30px;

      .downloadBtn {
        color: #1890FF;
        right: 0;
        position: absolute;
      }

      .errorTip {
        color: red;
      }
    }

    .dataBtn {
      margin-left: 10px;
    }
  }

  .progresscBtn {
    text-align: right;
    margin: 30px 0 10px;
  }

  .checkDetail {
    color: #1890ff;
    cursor: pointer;
    padding-left: 10px;
  }
}

.chargeds {
  margin-top: 5px;
  font-size: 12px;
}

.info-item-content {
  color: #000000a6;
}

.robotinfo {
  ::v-deep(.needGo) {
    span {
      color: rgba(0, 0, 0, 0.45);
      cursor: pointer;
    }
  }
}

.hardwardBox {
  padding: 20px;
  max-height: 550px;
  overflow: hidden;
  overflow-y: scroll;
  text-align: left;

  .normalTitle {
    line-height: 40px;
    font-weight: bold;
    font-size: 16px;
    color: #000;

    i {
      margin: 0 10px 0 0;
    }
  }

  .hardTitle {
    line-height: 28px;
    font-weight: bold;
    color: #000;
  }

  .hardItem {
    line-height: 24px;
    text-align: left;

    .itemTitle {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    .itemDes {
      float: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  }
}
</style>
